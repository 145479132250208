import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { removeHTMLTags } from 'utils/helpers';

import { IArticleSchema } from './model';

const ArticleSchema: FC<IArticleSchema> = ({ name, url, description, image }) => {
  const jsonLdSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Article',
      name,
      url,
      description: removeHTMLTags(description),
      image,
    }),
  };

  return <Helmet script={[jsonLdSchema]} />;
};

export default ArticleSchema;
